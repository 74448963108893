"use strict";

require("../vendor/jquery.slick");

(function($) {

  /**
   Default data-attribute based initialisation
   */
  $("[data-slick]").slick();

  /**
   Testimonial Carousel
   */
  $(".js-testimonials-slider").slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    appendArrows: $(".js-testimonials-slider"),
    prevArrow: '<button type="button" class="c-testimonial-slider__nav c-testimonial-slider__nav--prev slick-prev">Previous</button>',
    nextArrow: '<button type="button" class="c-testimonial-slider__nav c-testimonial-slider__nav--next slick-next">Next</button>',
    responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }
  ]
  });

  /**
   Benefits Carousel
   */
  $(".js-benefits-slider").slick({
    appendArrows: $(".js-benefits-slider"),
    prevArrow: '<button type="button" class="c-benefits-slider__nav c-benefits-slider__nav--prev slick-prev">Previous</button>',
    nextArrow: '<button type="button" class="c-benefits-slider__nav c-benefits-slider__nav--next slick-next">Next</button>',
    asNavFor: '.js-benefits-image-slider',
    adaptiveHeight: true
  });

  $(".js-benefits-image-slider").slick({
    arrows: false,
    fade: true,
    asNavFor: '.js-benefits-slider'
  });

  $(".fuel-slider").slick({
    arrows: false,
    dots: true,
    fade: true,
    adaptiveHeight: true
  });

  $('.fuel-slider').on('click', '.js-jump-slide', function () {
    $('.fuel-slider').slick('slickGoTo', $(this).data('index') - 1)
  });


})(jQuery);



