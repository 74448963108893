"use strict";

require("../vendor/jquery.validate");

(function($) {

  /* Validate all forms */
  $(".js-validate").validate();

  /**
    Infield labels
  */
  $('input, textarea, select').on('focus', function() {

    var
    id = $(this).attr('id'),
    label = 'label[for="' + id + '"]';

    $(this).addClass('has-value');
    $(label).addClass('has-value');

  }).on('blur', function() {

    var
    id = $(this).attr('id'),
    label = 'label[for="' + id + '"]',
    value = $(this).val();

    if (value.length <= 0) {
      $(this).removeClass('has-value');
      $(label).removeClass('has-value');
    }

  });

  // If infield label has value move label
  $('input, textarea, select').each(function() {

    var
    id = $(this).attr('id'),
    label = 'label[for="' + id + '"]',
    value = $(this).val();

    if (value.length <= 0) {
      $(this).removeClass('has-value');
      $(label).removeClass('has-value');
    } else {
      $(this).addClass('has-value');
      $(label).addClass('has-value');
    }

  });

})(jQuery);




