"use strict";

(function($) {

  $('#hero-trigger-1').waypoint(function(direction) {

    /**
      Change nav position to fixed and add margin to account for loss of space
    */

    if (direction == 'down') {

      $('.c-page-header').addClass('is-stage-2-rotate');
      setTimeout(function(){
        $('.c-page-header').addClass('is-stage-2');
      }, 300);

      $('.c-page-header').removeClass('is-stage-1');
      $('.c-page-header').removeClass('is-stage-1-rotate');

    } else {

      $('.c-page-header').removeClass('is-stage-2-rotate');

      setTimeout(function(){
        $('.c-page-header').addClass('is-stage-1');
        $('.c-page-header').removeClass('is-stage-2');
      }, 300);


    }

  });

  // Jump to hero trigger
  $( ".js-hero-arrow-1" ).on( "click", function(ev) {

    $('html, body').animate({
        scrollTop: $("#hero-trigger-1").offset().top
    }, 10);

    ev.preventDefault();

  });

  // Jump to main content
  $( ".js-hero-arrow-2" ).on( "click", function(ev) {

    $('html, body').animate({
        scrollTop: $("#sticky-nav").offset().top
    }, 1000);

    ev.preventDefault();

  });


})(jQuery);



