"use strict";

require("../vendor/jquery.waypoints");

(function($) {

  $('[data-animate]').waypoint(function(direction) {

    if (direction == 'down') {
      $(this.element).addClass('is-inview');
    } else {
      $(this.element).removeClass('is-inview');
    }

  }, { offset: '80%' } );

})(jQuery);
