"use strict";

var $ = require('./vendor/jquery');
window.jQuery = $;

require("./vendor/jquery.matchHeight");
require("./components/navigation");
require("./components/hero");
require("./components/carousel");
require("./components/collapse");
require("./components/form");
require("./components/waypoints");


