"use strict";

require("../vendor/jquery.waypoints");

(function($) {

  $(window).load(function() {

    /* Stick nav to top of viewport */
    $('#sticky-nav').waypoint(function(direction) {

      /**
        Change nav position to fixed and add margin to account for loss of space
      */

      if (direction == 'down') {
        $('#sticky-nav').addClass('is-stuck');
        $('.c-page-header').css( "margin-bottom", $('#sticky-nav').outerHeight() + 'px' );
      } else {
        $('#sticky-nav').removeClass('is-stuck');
        $('.c-page-header').css( "margin-bottom", 0 );
      }

    });

    $('#sticky-nav-small').waypoint(function(direction) {

      /**
        Change nav position to fixed and add margin to account for loss of space
      */

      if (direction == 'down') {
        $('#sticky-nav').addClass('is-small');
      } else {
        $('#sticky-nav').removeClass('is-small');
      }

    });

    var menuOffset = $('#sticky-nav').outerHeight();

    $('.js-anchor').waypoint(function(direction) {

      var href = $(this.element).attr('id');
      var $el = $('#' + href);


      if( direction == 'down') {

        if ($($el).length > 0){

          if( $('body').hasClass('is-scrolling') ) {

          } else {
            $('.js-jumplink').removeClass('is-active');
            $('.js-jumplink[href="#' + href + '"]').addClass('is-active');
          }

        }

      }

    }, { offset: menuOffset } );

    $('.js-anchor').waypoint(function(direction) {

      var href = $(this.element).attr('id');
      var $el = $('#' + href);

      if( direction == 'up') {

        if ($($el).length > 0){

          if( $('body').hasClass('is-scrolling') ) {

          } else {
            $('.js-jumplink').removeClass('is-active');
            $('.js-jumplink[href="#' + href + '"]').addClass('is-active');
          }

        }

      }

    },  { offset: function() {
          return -this.element.clientHeight + menuOffset
        }
    } );

  });

  // Toggle mobile navigation
  $( ".js-nav-toggle" ).on( "click", function(ev) {
    $(this).toggleClass('is-active');
    $('body').toggleClass('nav-is-active');
    ev.preventDefault();
  });

  // Anchour jump links
  $( ".js-jumplink" ).on( "click", function(ev) {

    var href = $(this).attr('href');
    var $el = $(href);
    var offset = $('#sticky-nav').outerHeight();

    // If element exists on the page
    if( $(href).length > 0) {

      $('html, body').animate({
        scrollTop: $el.offset().top - offset
      }, 500);

      $('body').addClass('is-scrolling');
      $('.js-jumplink').removeClass('is-active');
      $(this).addClass('is-active');

      // If mobile navigation is open
      if( $('body').hasClass('nav-is-active') ) {
        $('.js-nav-toggle').trigger('click');
      }

      // Remove scrolling state after animation has completed
      setTimeout(function(){
        $('body').removeClass('is-scrolling');
      }, 500);

    }

    ev.preventDefault();

  });

})(jQuery);